<template>
  <span v-if="text.html" class="header-text hide-on-mobile" v-html="nl2br(text.html)" />
</template>

<script>
export default {
  name: 'HeaderText',
  props: {
    text: {
      type: Object,
      default: null,
    },
  },
  methods: {
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
  },
}
</script>

<style lang="scss" scoped>
.header-text {
  display: flex;
  align-items: center;
  color: var(--header-custom-color, var(--header-color));

  &:first-child::after {
    content: '';
    width: 3.2rem;
    height: 0.2rem;
    background: var(--header-custom-color, var(--header-color));
    display: inline-block;
    margin-left: 1.6rem;
  }

  &.margin {
    margin-right: spacing('small');
  }
}
</style>
